import { useEffect, lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "./config";
import { Styles } from "../styles/styles";

const Router = () => {
	
	useEffect(() => {		
		downloadRedirect();
	}, []);

	const downloadRedirect = async () => {		
		const userAgent = window.navigator.userAgent.toLowerCase();
		const download = await logDownload(userAgent);
	}

	const logDownload = async (useragent) => {
		try {
			const response = await fetch("https://errandwithleye.com/process.php", {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({useragent: useragent})
			});

			if (!response.ok) {
				throw new Error("");
			}						
			const responseData = await response.json();			
		} catch (error) {}
	}
	
  return (
    <Suspense fallback={null}>
      <Styles />
      <Header />
      <Switch>
        {routes.map((routeItem) => {
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              exact={routeItem.exact}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}
      </Switch>
      <Footer />
    </Suspense>
  );
};

export default Router;
